export default {
  goToTop() {
    if (history.replaceState) {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      location.hash = '/';
    }
  },

  goToId(hash?: string) {
    if (hash === undefined || hash.length === 0) {
      this.goToTop();
      return;
    }

    if (history.pushState) {
      const el = document.getElementById(hash);

      if (el !== null) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    } else {
      location.hash = `#${hash}`;
    }
  },
};
