import * as React from 'react';

import Jumbotron from './components/Jumbotron';
import Topbar from './components/Topbar';

import AboutSection from './sections/AboutSection';
import ContactsSection from './sections/ContactsSection';
import FooterSection from './sections/FooterSection';
import ServicesSection from './sections/ServicesSection';

import styles from './App.module.css';

class App extends React.Component {
  public render() {
    return (
      <div className={styles.Wrapper}>
        <Topbar />
        <Jumbotron />
        <ServicesSection />
        <AboutSection />
        <ContactsSection />
        <FooterSection />
      </div>
    );
  }
}

export default App;
