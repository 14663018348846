import * as React from 'react';
import MenuItem from './components/MenuItem';
import { IMenuItem } from './components/MenuItem/MenuItem';

interface IProps {
  height: number;
}
interface IState {
  activeAnchor?: string;
}
const TopbarHeight = 80;

export const MenuItems: { [key: string]: IMenuItem } = {
  ABOUT: {
    anchor: 'about',
    index: 1,
    name: 'About',
  },
  CONTACT: {
    anchor: 'contact',
    index: 2,
    name: 'Contact',
  },
  SERVICES: {
    anchor: 'services',
    index: 0,
    name: 'Our services',
  },
};

const SortedMenuItems: IMenuItem[] = Object.values(MenuItems)
  .sort((a: IMenuItem, b: IMenuItem) => a.index - b.index);

class Menu extends React.Component<IProps, IState> {
  public state = {
    activeAnchor: undefined,
  };

  public componentWillMount() {
    let previousHash: string;

    const func = () => {
      const elements = Object
        .values(MenuItems)
        .map((item) => {
          const el = document.getElementById(item.anchor);

          let bounding;
          if (el !== null) {
            bounding = el.getBoundingClientRect();
          }

          return { ...item, el, bounding };
        })
        .filter((item) => {
          const { bounding } = item;

          if (!bounding) {
            return false;
          }

          return (bounding.top <= TopbarHeight && bounding.bottom >= TopbarHeight);
        });

      const hash = elements.length === 0 ? '/' : elements[0].anchor;

      if (previousHash === hash) {
        return;
      }

      this.setState({ activeAnchor: hash });
      previousHash = hash;
    };

    if (window !== null) {
      window.onscroll = func;
    }
  }

  public render() {
    return (
      <div
        className="ui secondary stackable menu"
        style={{ justifyContent: 'flex-end', height: `${this.props.height}px` }}
      >
        {
          SortedMenuItems.map((item: IMenuItem) => (
            <MenuItem
              item={item}
              key={item.anchor}
              active={this.state.activeAnchor === item.anchor}
            />
          ))
        }
      </div>
    );
  }
}

export default Menu;
