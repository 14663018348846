import * as React from 'react';
import Section from '../../components/Section';
import { MenuItems } from '../../components/Topbar/components/Menu';

export default class AboutSection extends React.PureComponent<{}> {
  public render() {
    return (
      <Section header={MenuItems.ABOUT.name} inverted={true} id={MenuItems.ABOUT.anchor}>
        <div className="ui container stackable grid">
          <div className="sixteen wide column">
            <p>Olstech AB provides consultancy service based on over 30 years of experience in the manufacturing industry and international project management.</p>
            <p>Wide experience from the cash handling industry with customers like Cash-in-transit companies, Banks, Mints and Central Banks.</p>
            <p>Core competence in coins, involving all aspects in the coin lifetime, from design and choice of material of the coin itself, to coin in circulation, verification, handling/packaging and material recycling.</p>
            <p>We are supplier independent and always provides the most cost-effective solutions for our clients.</p>
          </div>
        </div>
      </Section>
    );
  }
}
