import * as React from 'react';
import windowService from '../../services/windowService';
import './Link.styles.css';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  anchor?: string;
  title?: string;
}

class Link extends React.Component<IProps> {
  public static defaultProps = {
    className: '',
  };

  public render() {
    return (
      <a
       onClick={this.clickHandler}
       className={`${this.props.className} Link`}
       title={this.renderTitle()}
       >
        {this.props.children}
      </a>
    );
  }

  private renderTitle = () : string => {
    if (this.props.title) {
      return this.props.title;
    }

    // If anchor is missing it will automatically go to top
    let suffix = this.props.anchor || 'top';

    if (typeof this.props.children === 'string') {
      suffix = this.props.children;
    }

    return `Go to ${suffix}`;
  }

  private clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();

    windowService.goToId(this.props.anchor);
  }
}

export default Link;
