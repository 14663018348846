import * as React from 'react';
import styles from './Section.module.css';

export interface IProps extends IInnerProps {
  children?: React.ReactNode;
  header?: string;
  style?: React.CSSProperties;
  id?: string;
}

interface IInnerProps {
  inverted?: boolean;
  transparent?: boolean;
  centered?: boolean;
  nopadding?: boolean | 'bottom' | 'top';
}

const getInnerProps = (props: IProps) => ({
  centered: props.centered,
  inverted: props.inverted,
  nopadding: props.nopadding,
  transparent: props.transparent,
});

const getClassNames = (props: IProps) => {
  const classNames: string[] = ['ui', 'segment', 'basic', styles.Section];

  if (props.centered) {
    classNames.push('center aligned');
  }

  if (props.inverted) {
    classNames.push('inverted');
  }

  if (props.transparent) {
    classNames.push('transparent');
  }

  if (props.nopadding) {
    if (props.nopadding === 'top') {
      classNames.push('nopaddingtop');
    } else if (props.nopadding === 'bottom') {
      classNames.push('nopaddingbottom');
    } else {
      classNames.push('nopadding');
    }
  }

  return classNames.join(' ');
};

const Section: React.SFC<IProps> = (props) => {
  const innerProps = getInnerProps(props);

  return (
    <section style={props.style} id={props.id} className={getClassNames(props)}>
      {props.header && <h2 className={styles.Header}>{props.header}</h2>}
      {props.children}
    </section>
  );
};

Section.defaultProps = {
  inverted: false,
  nopadding: false,
  transparent: false,
};

export default Section;
