import * as React from 'react';
import { IService } from '../../../../data/Services';

export interface IProps {
  service: IService;
}

export interface IState {
  hovered: boolean;
}

const styles: { [key: string]: React.CSSProperties } = {
  circle: {
    borderColor: 'rgb(240, 240, 240)',
    borderRadius: '9999px',
    borderStyle: 'solid',
    borderWidth: '10px',
    display: 'inline-block',
    height: '150px',
    padding: '30px',
    transition: 'border-color 0.5s',
    width: '150px',
  },
  divider: {
    display: 'inline-block',
    height: '2px',
    margin: '0 0 20px',
    width: '40%',
  },
  header: {
    textTransform: 'uppercase',
  },
  wrapper: {
    textAlign: 'center',
  },
};

class ServiceBox extends React.Component<IProps, IState> {
  public state = {
    hovered: false,
  };

  public render() {
    const { service } = this.props;

    const circleStyle = { ...styles.circle };
    if (this.state.hovered) {
      circleStyle.borderColor = service.color;
    }

    return (
      <div onMouseEnter={this.setHover} onMouseLeave={this.unsetHover} style={styles.wrapper}>
        {service.logo && <div style={circleStyle}>
          <img src={service.logo} alt={service.header}/>
        </div>}
        <h3 style={styles.header}>{service.header}</h3>
        <div style={{ ...styles.divider, background: service.color }} />
        <p>{service.body}</p>
      </div>
    );
  }

  private setHover = () => this.setState({ hovered: true });
  private unsetHover = () => this.setState({ hovered: false });
}

export default ServiceBox;
