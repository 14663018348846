import * as React from 'react';
import Link from '../../components/Link';
import Section from '../../components/Section';
import { MenuItems } from '../../components/Topbar/components/Menu';
import styles from './FooterSection.module.css';

export default class FooterSection extends React.PureComponent<{}> {
  public render() {
    return (
      <Section inverted={true} nopadding="bottom">
        <footer className={styles.Footer}>
          <div className="ui container">
            <div className="ui grid stackable centered">
              <div className="six wide column">
                <h3 className={styles.Header}>Olstech</h3>
                <Link anchor={MenuItems.SERVICES.anchor}>{MenuItems.SERVICES.name}</Link><br />
                <Link anchor={MenuItems.ABOUT.anchor}>{MenuItems.ABOUT.name}</Link><br />
                <Link anchor={MenuItems.CONTACT.anchor}>{MenuItems.CONTACT.name}</Link>
              </div>
              <div className="six wide column">
                <h3 className={styles.Header}>Contact us</h3>
                <p>
                  <i className={`phone icon ${styles.Icon}`} />
                  <a title="Call us" href="tel:+46705674085">+46705674085</a>
                </p>
                <p>
                  <i className={`envelope outline icon ${styles.Icon}`} />
                  <a title="Email us" href="mailto:info@olstech.se">
                    info@olstech.se
                  </a>
                </p>
                <address>
                  <i className={`map marker alternate icon ${styles.Icon}`} />
                  <p style={{ display: 'inline-block', verticalAlign: 'top' }} >
                    Olsnäsvägen 14<br />
                    SE-471 98, Fagerfjäll<br />
                    Sweden
                  </p>
                </address>
              </div>
            </div>
          </div>
        </footer>
        <span className={styles.Copyright}>
          &copy; {(new Date()).getFullYear()} OLSTECH AB
        </span>
      </Section>
    );
  }
}
