import * as React from 'react';
import Link from '../../../../../Link';

export interface IMenuItem {
  name: string;
  anchor: string;
  index: number;
}

interface IProps {
  item: IMenuItem;
  active: boolean;
}

export default class MenuItem extends React.PureComponent<IProps> {
  public render() {
    const { item, active } = this.props;
    return (
      <Link anchor={item.anchor} className={`item ${active ? 'active' : ''}`}>
          {item.name}
      </Link>
    );
  }
}
