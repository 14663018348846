import * as React from 'react';
import Link from '../../../Link';
import styles from './Logo.module.css';

import logo from './logo@1x.png';
import logo2x from './logo@2x.png';

interface IProps {
  headerHeight: number;
}

class Logo extends React.PureComponent<IProps> {
  public render() {
    return (
      <div>
        <Link>
          <h1 className={styles.Header}>
            <img
              src={logo}
              className={styles.Logo}
              srcSet={`
                ${logo} 1x,
                ${logo2x} 2x
              `}
              alt="Olstech"
              height="50"
            />
          </h1>
        </Link>
      </div>
    );
  }
}

export default Logo;
