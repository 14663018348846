import * as React from 'react';

// import Section from '../../components/Section';
// import { MenuItems } from '../../components/Topbar/components/Menu';
// import ContactsForm from './components/ContactsForm';
// import styles from './ContactsSection.module.css';

export default class ContactsSection extends React.PureComponent<{}> {
  public render() {
    return null;
    // return (
    //   <Section header={MenuItems.CONTACT.name} id={MenuItems.CONTACT.anchor} transparent={true}>
    //     <p className={styles.Paragraph}>
    //       Use the form below, or the contact details further down, in order to get in touch with us.
    //     </p>
    //     <div className="ui container">
    //       <div className="ui grid stackable centered">
    //         <div className="twelve wide column">
    //           <ContactsForm />
    //         </div>
    //       </div>
    //     </div>
    //   </Section>
    // );
  }
}
