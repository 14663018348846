import * as React from 'react';

import Logo from './components/Logo';
import Menu from './components/Menu';
import styles from './Topbar.module.css';

const TopbarHeight = 80;

interface IState {
  expanded: boolean;
}

class Topbar extends React.Component<{}, IState> {
  public state = {
    expanded: false,
  };

  public toggleExpand = () => this.setState(state => ({ expanded: !state.expanded }));

  public render() {
    const wrapperClassNames = [styles.Wrapper];

    if (this.state.expanded) {
      wrapperClassNames.push('expanded');
    }

    return (
      <div className={wrapperClassNames.join(' ')}>
        <button
          title="Visa meny"
          className={`ui button icon ${styles.Hamburger}`}
          onClick={this.toggleExpand}
        >
          <i className="bars icon" />
        </button>
        <nav className="ui container stackable grid">
          <div className="twelve wide column">
            <Logo headerHeight={TopbarHeight} />
          </div>
          <div className="four wide column">
            <Menu height={TopbarHeight} />
          </div>
        </nav>
      </div>
    );
  }
}

export default Topbar;
