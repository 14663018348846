import * as React from 'react';

import Link from '../Link';
import Section from '../Section';
import { MenuItems } from '../Topbar/components/Menu';
import styles from './Jumbotron.module.css';

class Jumbotron extends React.PureComponent<{}> {
  public render() {
    return (
      <Section transparent={true} centered={true}>
        <div className={styles.Wrapper}>
          <div className={styles.Container}>
            <h2 className={styles.Header}>
              Independent consultants
            </h2>
            <div>
              <Link
                anchor={MenuItems.SERVICES.anchor}
                title="Show our services"
                className={`large ui red button ${styles.Button}`}
              >
              {MenuItems.SERVICES.name}
              </Link>
              <Link
                anchor={MenuItems.CONTACT.anchor}
                title="Show our contact details"
                className={`large ui green button ${styles.Button}`}
              >
              {MenuItems.CONTACT.name}
              </Link>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export default Jumbotron;
