import * as React from "react";

export interface IService {
  body: React.ReactNode;
  color: string;
  header: string;
  logo?: string;
}

const data: IService[] = [
  {
    body: (
      <React.Fragment>
        <p><strong>Consultancy services in regards of project management</strong></p>
        <p>Covering the full process in a project execution, either as supporting existing project managers or acting as project managers.</p>
        <p>From project evaluation and requirements, specification, tender documents, procurement, execution to final commissioning.</p>
      </React.Fragment>
    ),
    color: '#e96656',
    header: 'Project management',
  },
  {
    body: (
      <React.Fragment>
        <p><strong>Consultancy services in regards of international market development</strong></p>
        <p>Development of new geographical and market segments, evaluation of market potential both direct to end users as well as local distributors and already established sales channels.</p>
      </React.Fragment>
    ),
    color: '#34d293',
    header: 'Market development',
  },
  {
    body: (
      <React.Fragment>
        <p><strong>Consultancy services in regards of technical development of products and solutions</strong></p>
        <p>Technical advisory in development project considering, market requirements, customer added value features, technical solutions and design.</p>
      </React.Fragment>
    ),
    color: '#3ab0e2',
    header: 'Technical development',
  },
];

export default data;
