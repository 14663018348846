import * as React from 'react';

import Section from '../../components/Section';
import { MenuItems } from '../../components/Topbar/components/Menu';
import Services, { IService } from '../../data/Services';
import ServiceBox from './components/ServiceBox';

export interface IProps {
  children?: React.ReactNode;
}

export interface IState {
  services: IService[];
}

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    textAlign: 'center',
  },
};

const indexToWords = ['', 'one', 'two', 'three', 'four', 'five', 'six'];

export default class ServicesSection extends React.Component<IProps, IState> {
  public state = {
    services: Services,
  };

  public render() {
    return (
      <Section
        style={styles.wrapper}
        id={MenuItems.SERVICES.anchor}
        header={MenuItems.SERVICES.name}
      >
        <div
          className={[
            'ui',
            'container',
            indexToWords[this.state.services.length],
            'column',
            'stackable',
            'grid'].join(' ')
          }
        >
          {this.state.services.map(service => (
            <div className="column" key={service.header}>
              <ServiceBox service={service} />
            </div>
          ))}
        </div>
      </Section>
    );
  }
}
